import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

export interface AuthenticationState {
  discoveryDocumentLoaded: boolean;
  isAuthenticated: boolean;
  authenticationInProgress: boolean;
}

export interface AppUser {
  givenName: string;
  surname: string;
}

const initialState: AuthenticationState = {
  discoveryDocumentLoaded: false,
  isAuthenticated: false,
  authenticationInProgress: false,
};

export const AuthenticationStateStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store) => ({
    setAuthenticationInProgress(inProgress: boolean): void {
      patchState(store, {authenticationInProgress : inProgress});
    },
    setIsAuthenticated(authenticated: boolean): void {
      patchState(store, {isAuthenticated: authenticated});
    },
    setIsDiscoveryDocumentLoaded(loaded: boolean): void {
      patchState(store, {discoveryDocumentLoaded: loaded});
    },
  })),
  withComputed((store, oauthService = inject(OAuthService)) => ({
    userAttributes: computed(() => {
      const claims = oauthService.getIdentityClaims();
      if (claims != null) {
        const givenName = claims['given_name'];
        const surname = claims['family_name'];
        if (givenName !== undefined && surname !== undefined) {
          return { surname, givenName };
        } else {
          console.error('Could not set user on token received event.');
        }
      }
      return undefined;
    }),
  }))
);
