import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@frag-henning/auth';
import { defer, from, iif, of, switchMap } from 'rxjs';

export const CheckAuthenticationAndForwardIfNotApplicable: CanActivateFn = (
  route,
  state,
  router = inject(Router),
  service = inject(AuthService)
) => {
  return service.isAuthenticated().pipe(
    switchMap(authenticated =>
      iif(
        () => authenticated,
        of(true),
        defer(() => from(router.navigateByUrl(`/login?reqUrl=${state.url}`)))
      )
    )
  );
};
