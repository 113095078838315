<fh-state-persister xmlns="http://www.w3.org/1999/html"/>
<div class="absolute w-screen flex justify-between place-items-center px-6 bg-gray-200 z-[5] py-2">
  <img src="/assets/fsmb_icon.png" class="h-16 sm:hidden"/>
  <img src="/assets/fsmb_logo.png" class="h-16 hidden sm:block"/>
  <div class="flex">
    <div
      class="flex rounded-full w-10 h-10 place-items-center place-content-center hover:bg-gray-400 btn small">
      <fa-icon class="mx-2" [icon]="faPenToSquare" size="lg"/>
    </div>
    <div class="relative mx-2">
      <user-initials-icon [user]="userPlaceholder()" (click)="toggleMenu()"/>
      <div [ngClass]="menuExpanded() ? 'flex' : 'hidden'"
           class="absolute top-12 right-0 w-56 bg-gray-100 drop-shadow-md border-gray-300 border-[0.5px] rounded-2xl flex-col p-2">

        <div class="btn py-2 px-4 hover:bg-gray-300 rounded-lg flex place-items-center">
          <div class="flex place-content-center w-4">
            <fa-icon [icon]="faQuestion"/>
          </div>
          <div class="ml-3">About</div>
        </div>
        <div class="btn py-2 px-4 hover:bg-gray-300 rounded-lg flex place-items-center">
          <div class="flex place-content-center w-4">
            <fa-icon [icon]="faCog"/>
          </div>
          <div class="ml-3">Einstellungen</div>
        </div>

        <hr class="mx-4 border-gray-200 border-t-2 my-1"/>

        <div class="btn py-2 px-4 hover:bg-gray-300 rounded-lg flex place-items-center">
          <div class="flex place-content-center w-4">
            <fa-icon [icon]="faArrowRightFromBracket"/>
          </div>
          <div class="ml-3">Abmelden</div>
        </div>

      </div>
    </div>
  </div>
</div>
<router-outlet/>
