import { ChangeDetectionStrategy, Component, ElementRef, OnInit, signal } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { StatePersisterComponent } from './components/state-persister/state-persister.component';
import { AvatarIconComponent, FhFrameComponent, UserInitialsIconComponent } from '@frag-henning/core';
import { AppUser } from '@frag-henning/auth';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowRightFromBracket, faCog, faPenToSquare, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { NgClass } from '@angular/common';
import { ChatBotDashboardComponent } from '@frag-henning/chat';

@Component({
  selector: 'fh-orbit',
  standalone: true,
  imports: [RouterOutlet, StatePersisterComponent, FhFrameComponent, AvatarIconComponent, UserInitialsIconComponent, FaIconComponent, NgClass, ChatBotDashboardComponent, RouterLink],
  templateUrl: './frag-henning-orbit.component.html',
  styleUrl: './frag-henning-orbit.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FragHenningOrbitComponent implements OnInit {
  userPlaceholder = signal<AppUser | undefined>({givenName: "Tarek", surname: "Mues"});

  menuExpanded = signal<boolean>(false);

  constructor(private _elementRef: ElementRef) {
  }

  toggleMenu() {
    this.menuExpanded.set(!this.menuExpanded());
  }

  ngOnInit(): void {
    this._elementRef.nativeElement.removeAttribute("ng-version");
  }

  protected readonly faPenToSquare = faPenToSquare;
  protected readonly faArrowRightFromBracket = faArrowRightFromBracket;
  protected readonly faCog = faCog;
  protected readonly faQuestion = faQuestion;
}
