import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { iif, map, pipe, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { CoreLoginService } from '../services/api/core-login.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { ApiHealthService, ApiStatus } from '../services/api/api-health.service';
import { hasKey } from '@frag-henning/utils';
import { instantInterval } from '@frag-henning/utils';

export enum StudyCourse {
  Maschinenbau = 'Maschinenbau',
  WirtIngFrMaschinenbau = 'Wirtschaftsingenieurswesen FR Maschinenbau',
  CES = 'Computational Engineering Science',
}

export interface AppUser {
  givenName: string;
  surname: string;
}

export interface ApplicationState {
  apiHealthy: boolean | undefined;
  hasReadTermsOfUse: boolean;
  studyCourse: StudyCourse | undefined;
}

const initialState: ApplicationState = {
  apiHealthy: undefined,
  hasReadTermsOfUse: false,
  studyCourse: undefined,
};

function parseToStudyCourse(value: string): StudyCourse | undefined {
  const enumValues = Object.values(StudyCourse);

  for (const enumValue of enumValues) {
    if (enumValue === value) {
      return enumValue as unknown as StudyCourse;
    }
  }
  return undefined;
}

export const AppStateStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store, apiHealthService = inject(ApiHealthService), coreLoginService = inject(CoreLoginService)) => ({
    initTermsOfUse(): void {
      const storedCourse = localStorage.getItem('studyCourse');
      if (storedCourse != null) {
        const parsedCourse = parseToStudyCourse(storedCourse);
        if (parsedCourse != undefined) {
          patchState(store, { studyCourse: parsedCourse });
        }
      }
    },
    retrieveUserAppPermissions: rxMethod<void>(
      pipe(
        switchMap(() =>
          coreLoginService.checkPermissions().pipe(
            tapResponse({
              next: result => {
                if (hasKey(result._links, 'chat/usage')) {
                  patchState(store);
                }
              },
              error: () => {
                console.error('Error while retrieving user app permissions');
              },
            })
          )
        )
      )
    ),
    doApiHealthCheck: rxMethod<void>(
      pipe(
        switchMap(() => toObservable(store.apiHealthy)),
        switchMap(health =>
          iif(() => health !== undefined && health == true, instantInterval(20000), instantInterval(5000)).pipe(
            switchMap(() =>
              apiHealthService.checkStatus().pipe(
                map(status => status.status === ApiStatus.Up),
                tapResponse({
                  next: health => patchState(store, { apiHealthy: health }),
                  error: health => patchState(store, { apiHealthy: false }),
                })
              )
            )
          )
        )
      )
    ),
  })),
  withHooks({
    onInit({ doApiHealthCheck, retrieveUserAppPermissions }) {
      //retrieveUserAppPermissions();
      //doApiHealthCheck();
    },
  })
);
