import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '@frag-henning/auth';
import { from, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Subscriptions } from '@frag-henning/utils';

@Component({
  selector: 'fh-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  private subs: Subscriptions = new Subscriptions();

  private readonly authService = inject(AuthService);

  private readonly router = inject(ActivatedRoute);

  ngOnInit(): void {
    const requestedUrl = this.router.snapshot.queryParamMap.get('reqUrl');
    this.subs.put(
      this.authService
        .triggerAuthentication(requestedUrl ?? undefined)
        .pipe(switchMap(() => from(Promise.resolve(true))))
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribeAll();
  }
}
