import { Inject, inject, Injectable } from '@angular/core';
import { AuthenticationStateStore } from './auth.state';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { catchError, filter, from, iif, map, mergeMap, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { canDecodeURIComponent } from './utils';
import { AUTH_CONFIG, AuthConfigurationProperties } from '@frag-henning/injection-context';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly state = inject(AuthenticationStateStore);
  private readonly oauthService = inject(OAuthService);
  private readonly router = inject(Router);

  private readonly config: AuthConfig;

  constructor(@Inject(AUTH_CONFIG) authConfig: AuthConfigurationProperties) {
    this.config = {
      issuer: authConfig.issuer,
      redirectUri: authConfig.redirectUri,
      clientId: authConfig.clientId,
      responseType: 'code',
      scope: `profile email offline_access ${authConfig.scopes.join(' ')}`,
      showDebugInformation: authConfig.verboseLogging,
      timeoutFactor: 0.75,
    };

    this.oauthService.configure(this.config);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.waitForTokenInMsec = 2000;

    this.oauthService.events.subscribe(event => {
      //if (environment.verboseLogging) {
      if (event instanceof OAuthErrorEvent) {
        console.info('OAuthErrorEvent Object:', event);
      } else {
        console.info('OAuthEvent Object:', event);
      }
      //}
    });

    window.addEventListener('storage', event => {
      if (event.key !== 'access_token' && event.key !== 'id_token') {
        return;
      }

      console.warn('Noticed changes to stored tokens (most likely from another tab)');
      if (!this.allTokensValid()) {
        this.state.setIsAuthenticated(false);
        this.triggerAuthentication().subscribe();
      }
    });

    this.oauthService.events
      .pipe(tap(() => this.state.setAuthenticationInProgress(this.oauthService.hasValidAccessToken())))
      .subscribe();

    this.oauthService.events
      .pipe(
        filter(e => ['token_received'].includes(e.type)),
        mergeMap(() => {
          const state = this.oauthService.state;
          if (state != undefined && canDecodeURIComponent(state)) {
            return from(this.router.navigateByUrl(decodeURIComponent(state)));
          }
          return of(void 0);
        })
      )
      .subscribe();

    this.oauthService.events
      .pipe(
        filter(e => ['session_terminated', 'session_error'].includes(e.type)),
        tap(() => this.state.setAuthenticationInProgress(false))
      )
      .subscribe();
  }

  public triggerAuthentication(requestedUrl?: string): Observable<boolean> {
    this.state.setAuthenticationInProgress(true);
    return this.loadDiscoveryDocument().pipe(
      switchMap(result =>
        iif(
          () => result,
          of(void 0).pipe(
            tap(() => this.login(requestedUrl)),
            map(() => true)
          ),
          throwError(() => {
            this.state.setAuthenticationInProgress(false);
            return new Error('Discovery Document fetching threw error. Aborting sign in.');
          })
        )
      ),
      tap(() => this.state.setAuthenticationInProgress(false))
    );
  }

  public isAuthenticated(): Observable<boolean> {
    return this.loadDiscoveryDocument().pipe(map(() => this.allTokensValid()));
  }

  public handleLoginRedirect(): Observable<boolean> {
    this.state.setAuthenticationInProgress(true);
    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      switchMap(result => {
        if (result) {
          this.state.setIsDiscoveryDocumentLoaded(true);
          if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
            this.state.setIsAuthenticated(true);
            return of(true);
          } else {
            //Do nothing as login flow gets initiated and the browser should redirect soon...
          }
        } else {
          this.state.setAuthenticationInProgress(false);
        }
        return of(false);
      }),
      catchError(() => {
        this.state.setAuthenticationInProgress(false);
        return of(false);
      })
    );
  }

  private loadDiscoveryDocument(): Observable<boolean> {
    if (!this.state.discoveryDocumentLoaded()) {
      return from(this.oauthService.loadDiscoveryDocument()).pipe(
        tap(() => this.state.setIsDiscoveryDocumentLoaded(true)),
        map(() => true),
        catchError(() => {
          console.error('Error while retrieving oid configuration document.');
          return of(false);
        })
      );
    } else {
      return of(true);
    }
  }

  private allTokensValid(): boolean {
    if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
      this.state.setIsAuthenticated(true);
      return true;
    }
    return false;
  }

  private login(requestedUrl?: string): void {
    if (!this.allTokensValid()) {
      this.oauthService.initCodeFlow(requestedUrl);
    } else {
      this.state.setIsAuthenticated(true);
    }
  }
}
