import { Route } from '@angular/router';
import { UnauthorizedComponent } from './app/components/unauthorized/unauthorized.component';
import {
  CheckAuthenticationAndForwardIfNotApplicable, LoginComponent,
  LoginRedirect, LoginRedirectComponent,
  RedirectIfAuthenticated
} from '@frag-henning/core';

export const webappRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/chat'
  },
  {
    path: 'chat',
    canActivate: [CheckAuthenticationAndForwardIfNotApplicable],
    loadChildren: () => import('@frag-henning/chat').then(m => m.chatRoutes),
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [RedirectIfAuthenticated],
  },
  {
    path: 'loginRedirect',
    component: LoginRedirectComponent,
    canActivate: [LoginRedirect],
  },
  {
    path: '401',
    component: UnauthorizedComponent,
    data: {redirectToIfLoggedIn: '/'},
  },
  /*{ path: '**', pathMatch: 'full', redirectTo: '/' },*/
];
