import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { AppStateStore } from '../../state/app.state';

@Component({
  selector: 'fh-state-persister',
  standalone: true,
  imports: [],
  templateUrl: './state-persister.component.html',
  styleUrl: './state-persister.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatePersisterComponent {
  private readonly appState = inject(AppStateStore);

  constructor() {
    effect(() => {
      const course = this.appState.studyCourse();
      if (course != undefined) {
        localStorage.setItem('studyCourse', course);
      }
    });
  }
}
