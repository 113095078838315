export const environment = {
  verboseLogging: true,
  production: true,
  chatbot: {
    maxInteractionsPerConversation: 0,
    persistConversation: true,
  },
  api: {
    scopes: [],
    baseUrl: 'https://frag-henning-dev.fsmb.rwth-aachen.de/api',
  },
  auth: {
    clientId: 'webapp',
    authority: 'https://auth.fsmb.rwth-aachen.de/realms/frag-henning-dev',
    redirectUri: 'https://frag-henning-dev.fsmb.rwth-aachen.de/loginRedirect',
  },
};
