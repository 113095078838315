import { APP_INITIALIZER, ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MARKED_OPTIONS, MarkedOptions, MarkedRenderer, provideMarkdown } from 'ngx-markdown';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { environment } from './environments/environment';
import { webappRoutes } from './app.routes';
import {
  AUTH_CONFIG,
  AuthConfigurationProperties,
  CHAT_API_CONFIG,
  ChatBotApiConfigurationProperties,
  CONVERSATION_BEHAVIOR_CONFIG,
} from '@frag-henning/injection-context';

function initializeIcons(library: FaIconLibrary) {
  return (): undefined => {
    library.addIconPacks(fas);
  };
}

function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const linkRenderer = renderer.link;

  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" ');
  };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
  };
}

const authConfig: AuthConfigurationProperties = {
  issuer: environment.auth.authority,
  redirectUri: environment.auth.redirectUri,
  clientId: environment.auth.clientId,
  scopes: [],
  verboseLogging: false,
};

const chatApiConfig: ChatBotApiConfigurationProperties = {
  apiBaseUrl: environment.api.baseUrl,
};

const conversationBehavior = {
  persistConversation: true,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    FontAwesomeModule,

    {
      provide: APP_INITIALIZER,
      useFactory: initializeIcons,
      deps: [FaIconLibrary],
      multi: true,
    },

    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      },
    }),

    {
      provide: AUTH_CONFIG,
      useValue: authConfig,
    },

    {
      provide: CHAT_API_CONFIG,
      useValue: chatApiConfig,
    },

    {
      provide: CONVERSATION_BEHAVIOR_CONFIG,
      useValue: conversationBehavior,
    },

    provideRouter(webappRoutes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    provideOAuthClient({ resourceServer: { allowedUrls: [environment.api.baseUrl], sendAccessToken: true } }),
  ],
};
