import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HasLinks } from '@frag-henning/utils';

export interface LoginResult extends HasLinks {}

@Injectable({
  providedIn: 'root',
})
export class CoreLoginService {
  constructor(private http: HttpClient) {}

  public checkPermissions(): Observable<LoginResult> {
    return this.http.post<LoginResult>(`${environment.api.baseUrl}/login`, {});
  }
}
