import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { AppUser } from '@frag-henning/auth';

@Component({
  selector: 'user-initials-icon',
  standalone: true,
  imports: [],
  templateUrl: './user-initials-icon.component.html',
  styleUrl: './user-initials-icon.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInitialsIconComponent {
  user = input.required<AppUser | undefined>();
  userInitials = computed(() => {
    const user = this.user();
    if (user !== undefined) {
      return user.givenName.substring(0, 1).toLocaleUpperCase() + user.surname.substring(0, 1).toLocaleUpperCase();
    } else {
      return null;
    }
  });
}
