import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@frag-henning/auth';
import { defer, iif, of, switchMap, from } from 'rxjs';

export const RedirectIfAuthenticated: CanActivateFn = (
  route,
  state,
  router = inject(Router),
  service = inject(AuthService)
) => {
  return service.isAuthenticated().pipe(
    switchMap(authenticated =>
      iif(
        () => authenticated,
        defer(() => from(router.navigateByUrl('/'))),
        of(true)
      )
    )
  );
};
