import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export enum ApiStatus {
  Up = 'UP',
  Down = 'DOWN',
}

export interface ApiHealthCheckResponse {
  status: ApiStatus;
}

@Injectable({
  providedIn: 'root',
})
export class ApiHealthService {
  constructor(private http: HttpClient) {}

  public checkStatus(): Observable<ApiHealthCheckResponse> {
    return this.http.get<ApiHealthCheckResponse>(`${environment.api.baseUrl}/health`);
  }
}
